import { getPennies, safeToUtc } from '@dltru/dfa-models'
import {
    Alert,
    Box,
    Button,
    DocumentDownload,
    Form,
    Moment,
    Space,
    Step,
    StepperContext,
    useEditable,
} from '@dltru/dfa-ui'
import { FC, useContext } from 'react'
import { useDispatch } from 'react-redux'

import { downloadDecisionTemplate } from '@store/dfa/details'

import { UploadInput } from '@components/Form'

import '../style.less'

interface IComponentProps {
    stepIndex: number
    makingTypeOutOfPlatform: boolean
}

export const DecisionStep: FC<IComponentProps> = ({ stepIndex, makingTypeOutOfPlatform }) => {
    const { editable, currentStep, stepsTotal, values } = useContext(StepperContext)
    const reduxDispatch = useDispatch()
    const [form] = Form.useForm()

    const isEdit = useEditable(currentStep, stepIndex, editable)
    const allowEdit =
        currentStep >= stepsTotal && (!editable.length || editable.includes(stepIndex))

    const downloadDocument = () => {
        if (!values) {
            return
        }

        const dfaData = {
            asset_title: values.title as string,
            asset_ticker_symbol: values.ticker_symbol as string,
            number: 0,
            price_per_dfa: getPennies(values.price_per_dfa as string),
            original_amount: Number(values.original_amount),
            redeem_price_per_dfa: getPennies(values.redeem_price_per_dfa as string),
            redeem_date: safeToUtc(values.redeem_date as Moment, true) as number,
            issue_start_date: safeToUtc(values.issue_start_date as Moment, true) as number,
            issue_end_date: safeToUtc(values.issue_end_date as Moment, true) as number,
            investors: values.investors as string[],
        }
        reduxDispatch(downloadDecisionTemplate(dfaData))
    }

    const alertDescription = (
        <Space size={36}>
            <div>
                <ul className="decision-alert-list">
                    <li>Скачайте шаблон Решения.</li>
                    <li>Подпишите электронной подписью в удобном для вас сервисе. </li>
                    <li>Скачайте файл подписи</li>
                    <li>Загрузите в систему подписанное Решение</li>
                    <li>Загрузите в систему файл подписи с расширением .p7s, .sign, .sig, .sgn</li>
                </ul>
            </div>
            <Button
                onClick={downloadDocument}
                type="link"
                className="decision-alert__button"
                borderRadius={12}
                width="78px"
            >
                <Space direction="vertical" size={6}>
                    <DocumentDownload />
                    СКАЧАТЬ
                </Space>
            </Button>
        </Space>
    )
    return (
        <Step
            stepIndex={stepIndex}
            form={form}
            stepTitle="Подписанное решение о выпуске"
            allowEdit={allowEdit}
            saveAfterEdit
        >
            <Box justify="space-between">
                {!isEdit && !makingTypeOutOfPlatform && (
                    <Box margin={[0, 0, 24, 240]}>
                        <Alert showIcon type="info" description={alertDescription} width={500} />
                    </Box>
                )}
                <div className="formRow">
                    <UploadInput
                        className="formComponentItem upload-form__styled"
                        name="document"
                        uneditable={isEdit}
                        label="Файл Решения"
                        required
                        labelOnlyUneditable={false}
                        title="Загрузите подписанное Решение о выпуске ЦФА. Допустимый формат файлов JPG, JPEG, TIFF, PDF, PNG, DOC, DOCX, TXT, RTF, ODT. Общий вес не более 25 МБ"
                        maxSizeMb={5}
                        maxCount={1}
                        accept=".jpg, .jpeg, .tiff, .pdf, .png, .doc, .docx, .txt, .rtf, .odt"
                    />
                </div>
                <div className="formRow">
                    <UploadInput
                        className="formComponentItem upload-form__styled"
                        name="document_signature"
                        uneditable={isEdit}
                        label="Файл подписи"
                        required
                        title="Загрузите файл подписи с расширением SIG, SGN"
                        accept=".sig,.sgn"
                        maxSizeMb={5}
                        maxCount={1}
                        labelOnlyUneditable={false}
                    />
                </div>
            </Box>
        </Step>
    )
}
