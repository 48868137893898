import {
    CardDivider,
    RedeemDateBlock,
    RedeemPriceBlock,
    WrappedDateItem,
    WrappedInput,
    currentFloatNumber,
    dfaValidators,
} from '@dltru/dfa-ui'
import moment, { Moment } from 'moment'
import FieldContext from 'rc-field-form/lib/FieldContext'
import { FC, useContext, useEffect, useState } from 'react'

interface IPriceSourceStaticProps {
    isEdit: boolean
    isManual: boolean
}

export const PriceSourceStatic: FC<IPriceSourceStaticProps> = ({ isEdit, isManual }) => {
    const { getFieldValue, validateFields } = useContext(FieldContext)
    const [issueStartDate, setIssueStartDate] = useState<Moment | undefined>()
    const [issueEndDate, setIssueEndDate] = useState<Moment | undefined>()
    useEffect(() => {
        setIssueStartDate(getFieldValue('issue_start_date'))
        setIssueEndDate(getFieldValue('issue_end_date') ?? getFieldValue('issue_start_date'))
    }, [isEdit])
    const onChangeIssueStartDate = (value: Moment | null) => {
        setIssueStartDate(value ?? undefined)
        if (getFieldValue('issue_end_date')) {
            validateFields(['issue_end_date'])
        } else {
            setIssueEndDate(value ?? undefined)
            if (getFieldValue('redeem_date')) {
                validateFields(['redeem_date'])
            }
        }
    }
    const onChangeIssueEndDate = (value?: Moment | null) => {
        setIssueEndDate(value ?? undefined)
        if (getFieldValue('redeem_date')) {
            validateFields(['redeem_date'])
        }
    }

    return (
        <>
            <div className="formRow">
                <WrappedInput
                    className="formComponentItem"
                    label="Цена ЦФА, руб."
                    name="price_per_dfa"
                    placeholder="Цена единицы"
                    uneditable={isEdit}
                    required
                    rules={[{ validator: dfaValidators.price }]}
                    normalize={currentFloatNumber}
                />
            </div>
            <div className="formRow">
                <WrappedDateItem
                    className="formComponentItem"
                    label="Дата начала размещения ЦФА"
                    name="issue_start_date"
                    uneditable={isEdit}
                    required
                    rules={[{ validator: dfaValidators.issueStartDate }]}
                    showTime={{ defaultValue: moment('10:00', 'HH:mm') }}
                    onChange={onChangeIssueStartDate}
                />
            </div>
            {!isManual && (
                <div className="formRow">
                    <WrappedDateItem
                        className="formComponentItem"
                        label="Дата окончания размещения ЦФА"
                        name="issue_end_date"
                        uneditable={isEdit}
                        required
                        rules={[{ validator: dfaValidators.issueEndDate(issueStartDate) }]}
                        showTime={{ defaultValue: moment('20:00', 'HH:mm') }}
                        onChange={onChangeIssueEndDate}
                    />
                </div>
            )}

            <CardDivider />

            <div className="formRow">
                <WrappedInput
                    className="formComponentItem formComponentItem--xl"
                    label="Условие признания выпуска состоявшимся"
                    name="issue_conditions"
                    uneditable={isEdit}
                    multiline
                    required={isManual}
                    disabled={!isManual}
                />
            </div>
            <CardDivider />

            <div className="formRow">
                <RedeemPriceBlock uneditable={isEdit} />
            </div>
            <div className="formRow">
                <RedeemDateBlock uneditable={isEdit} dateForValidate={issueEndDate} />
            </div>
        </>
    )
}
