import { Form, Step, StepperContext, useEditable } from '@dltru/dfa-ui'
import { FC, useContext } from 'react'

import { UploadInput } from '@components/Form'

interface IComponentProps {
    stepIndex: number
}

export const ApplicationStep: FC<IComponentProps> = ({ stepIndex }) => {
    const { editable, currentStep, stepsTotal } = useContext(StepperContext)
    const [form] = Form.useForm()

    const isEdit = useEditable(currentStep, stepIndex, editable)
    const allowEdit =
        currentStep >= stepsTotal && (!editable.length || editable.includes(stepIndex))

    return (
        <Step
            stepIndex={stepIndex}
            form={form}
            stepTitle="Подписанное заявление на допуск ЦФА к выпуску"
            allowEdit={allowEdit}
            saveAfterEdit
        >
            <div className="formRow">
                <UploadInput
                    className="formComponentItem upload-form__styled"
                    name="admission_application"
                    uneditable={isEdit}
                    required
                    label="Файл заявления"
                    labelOnlyUneditable={false}
                    title="Загрузите подписанное Заявление о допуске ЦФА к выпуску. Допустимый формат файлов JPG, JPEG, TIFF, PDF, PNG, DOC, DOCX, TXT, RTF, ODT. Общий вес не более 25 МБ"
                    accept=".jpg, .jpeg, .tiff, .pdf, .png, .doc, .docx, .txt, .rtf, .odt"
                    maxSizeMb={5}
                    maxCount={1}
                />
            </div>
            <div className="formRow">
                <UploadInput
                    className="formComponentItem upload-form__styled"
                    name="admission_application_signature"
                    uneditable={isEdit}
                    label="Файл подписи"
                    required
                    title="Загрузите файл подписи с расширением SIG, SGN"
                    accept=".sig,.sgn"
                    maxSizeMb={5}
                    maxCount={1}
                    labelOnlyUneditable={false}
                />
            </div>
        </Step>
    )
}
