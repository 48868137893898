import { Alert, Form, Step, StepperContext, useEditable } from '@dltru/dfa-ui'
import { FC, useContext } from 'react'

import { UploadInput } from '@components/Form/components'

interface IComponentProps {
    stepIndex: number
}

export const AttachmentsStep: FC<IComponentProps> = ({ stepIndex }) => {
    const { editable, currentStep, stepsTotal } = useContext(StepperContext)
    const [form] = Form.useForm()

    const isEdit = useEditable(currentStep, stepIndex, editable)
    const allowEdit =
        currentStep >= stepsTotal && (!editable.length || editable.includes(stepIndex))

    return (
        <Step
            stepIndex={stepIndex}
            form={form}
            stepTitle="Иные документы"
            allowEdit={allowEdit}
            saveAfterEdit
        >
            <div className="formRow">
                <UploadInput
                    className="formComponentItem form-item--upload"
                    name="attachments"
                    label="Прилагаемые документы"
                    uneditable={isEdit}
                    labelOnlyUneditable
                    required
                    title="Допустимый формат файлов JPG, JPEG, TIFF, PDF, PNG, DOC, DOCX, TXT, RTF, ODT. Общий вес не более 25 МБ"
                    maxSizeMb={5}
                    maxCount={5}
                    accept=".jpg, .jpeg, .tiff, .pdf, .png, .doc, .docx, .txt, .rtf, .odt"
                />
            </div>
            {!isEdit && (
                <Alert
                    showIcon
                    type="info"
                    description={
                        <>
                            К заявлению должно быть приложено описание деятельности лица,
                            выпускающего ЦФА, составленное по{' '}
                            <a
                                href="https://www.dltru.org/disclosure/pravila/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                установленной форме.
                            </a>
                        </>
                    }
                />
            )}
        </Step>
    )
}
