// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".countDFAWrap_lHrYq {\n  display: flex;\n  flex-wrap: wrap;\n  column-gap: 10px;\n}\n.countDFAWrap_lHrYq .ant-form-item-control_ot7zG {\n  width: 200px;\n}\n.countDFAWrap_lHrYq .priceWrap_disabled_wpuDr {\n  width: 100%;\n  padding-left: 240px;\n}\n.countDFAWrap_lHrYq .fixedCountInput_xVMje {\n  width: 390px;\n}\n.countDFAWrap_lHrYq .diapasonPrice_BNx2F {\n  display: flex;\n  align-items: center;\n}\n.countDFAWrap_lHrYq .diapasonPrice_BNx2F .priceSeparator_DxYnU {\n  color: #B9BAC1;\n  font-size: 16px;\n  line-height: 24px;\n  padding: 0 10px 24px 10px;\n}\n.countDFAWrap_lHrYq .diapasonPrice_BNx2F .priceSeparator_disabled_cXUIu {\n  padding: 0 10px;\n  color: inherit;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/DFAIssueDecisionPage/components/steps/ConditionStep/style.m.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,eAAA;EACA,gBAAA;AACF;AAJA;EAMI,YAAA;AACJ;AAGI;EACE,WAAA;EACA,mBAAA;AADN;AAXA;EAiBI,YAAA;AAHJ;AAdA;EAqBI,aAAA;EACA,mBAAA;AAJJ;AAlBA;EAyBM,cAAA;EACA,eAAA;EACA,iBAAA;EACA,yBAAA;AAJN;AAMM;EACE,eAAA;EACA,cAAA;AAJR","sourcesContent":[".countDFAWrap {\n  display: flex;\n  flex-wrap: wrap;\n  column-gap: 10px;\n\n  .ant-form-item-control {\n    width: 200px;\n  }\n\n  .priceWrap {\n    &_disabled {\n      width: 100%;\n      padding-left: 240px;\n    }\n  }\n\n  .fixedCountInput {\n    width: 390px;\n  }\n\n  .diapasonPrice {\n    display: flex;\n    align-items: center;\n\n    .priceSeparator {\n      color: #B9BAC1;\n      font-size: 16px;\n      line-height: 24px;\n      padding: 0 10px 24px 10px;\n\n      &_disabled {\n        padding: 0 10px;\n        color: inherit;\n      }\n    }\n  }\n}\n\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"countDFAWrap": "countDFAWrap_lHrYq",
	"ant-form-item-control": "ant-form-item-control_ot7zG",
	"priceWrap_disabled": "priceWrap_disabled_wpuDr",
	"fixedCountInput": "fixedCountInput_xVMje",
	"diapasonPrice": "diapasonPrice_BNx2F",
	"priceSeparator": "priceSeparator_DxYnU",
	"priceSeparator_disabled": "priceSeparator_disabled_cXUIu"
};
export default ___CSS_LOADER_EXPORT___;
