import { FC, useContext, useState } from 'react'

import {
    DecisionMakingType,
    ExternalRateIndex,
    ExternalRateIndexTranslate,
    IssueTypeDfa,
    PriceSource,
    TypeOfLaw,
    issueTypeDfaTranslate,
    priceSourceTranslate,
    stringToNumber,
    typeOfLawTranslate,
} from '@dltru/dfa-models'
import { SupplyInvestedType } from '@dltru/dfa-models/src/models/dfa/countDfa'
import {
    CardDivider,
    EarlyRepayment,
    Form,
    IValues,
    PriceSourceDynamic,
    Step,
    StepperContext,
    VIEW_DATE_FORMAT,
    WrappedInput,
    WrappedSelect,
    calcPricePerDfa,
    openMessage,
    useEditable,
} from '@dltru/dfa-ui'

import { getExternalRateHelper } from '@store/helper'

import { CountDFA } from '@pages/DFAIssueDecisionPage/components/steps/ConditionStep/CountDFA'
import { PriceSourceStatic } from '@pages/DFAIssueDecisionPage/components/steps/ConditionStep/PriceSourceStatic'

import { TickerInput } from '@components/Form'

const optionsTypeOfLaw = Object.entries(typeOfLawTranslate).map(([key, value]) => ({
    key,
    value: key,
    label: value,
}))

const optionsPriceSourceType = Object.entries(priceSourceTranslate).map(([key, value]) => ({
    key,
    value: key,
    label: value,
}))

const optionsIssueType = Object.entries(issueTypeDfaTranslate).map(([key, value]) => ({
    key,
    value: key,
    label: value,
}))

const optionsPriceSource = Object.entries(ExternalRateIndexTranslate).map(([key, value]) => ({
    key,
    value: key,
    label: value,
}))

interface IComponentProps {
    stepIndex: number
    decisionMakingType?: DecisionMakingType
    backFromFirstStep?: () => void
}

export const ConditionStep: FC<IComponentProps> = ({
    decisionMakingType,
    backFromFirstStep,
    stepIndex,
}) => {
    const { editable, currentStep, stepsTotal, values } = useContext(StepperContext)
    const [form] = Form.useForm()

    const [isManual, setIsManual] = useState(
        form.getFieldValue('issue_type') === IssueTypeDfa.manual,
    )

    const [isPriceTypeDynamic, setIsPriceTypeDynamic] = useState(
        form.getFieldValue('price_source_type') === PriceSource.dynamic,
    )

    const [isDiapasonPrice, setIsDiapasonPrice] = useState(
        form.getFieldValue('supply_invested_type') === SupplyInvestedType.diapason,
    )

    const [rateIndex, setRateIndex] = useState(ExternalRateIndex.GLDRUB_TOM)

    const isEdit = useEditable(currentStep, stepIndex, editable)
    const allowEdit =
        currentStep >= stepsTotal && (!editable.length || editable.includes(stepIndex))

    const onChange = (changedValues: IValues | null) => {
        if (
            changedValues &&
            ('issue_end_date' in changedValues ||
                'max_supply_invested' in changedValues ||
                'min_supply_invested' in changedValues ||
                'supply_invested_type' in changedValues) &&
            !isManual
        ) {
            let conditionsText = ''
            const amount = isDiapasonPrice
                ? form.getFieldValue('min_supply_invested')
                : form.getFieldValue('max_supply_invested')
            const date = form.getFieldValue('issue_end_date')?.format(VIEW_DATE_FORMAT)
            if (amount && date) {
                conditionsText = `Заключение договоров о приобретении ЦФА в отношении не менее чем ${amount} ЦФА в срок не позднее ${date}`
            }
            form.setFieldsValue({ issue_conditions: conditionsText })
        }

        if (changedValues && 'issue_type' in changedValues) {
            setIsManual(changedValues.issue_type === IssueTypeDfa.manual)
            if (changedValues.issue_type === IssueTypeDfa.manual) {
                // form.setFieldsValue({ issue_end_date: undefined })
            }
        }

        if (changedValues && 'price_source_type' in changedValues) {
            const isDynamicPrice = changedValues.price_source_type === PriceSource.dynamic
            setIsPriceTypeDynamic(isDynamicPrice)
            form.setFieldsValue({
                supply_invested_type: isDynamicPrice
                    ? SupplyInvestedType.diapason
                    : SupplyInvestedType.exactly,
            })
            setIsDiapasonPrice(isDynamicPrice)
        }

        if (changedValues && 'investment_spread' in changedValues) {
            const priceExternal = Number(form.getFieldValue('price_external'))
            const spread = stringToNumber(changedValues.investment_spread as unknown as string) || 0
            const pricePerDfa = (priceExternal / 100) * spread + priceExternal

            form.setFieldsValue({
                price_per_dfa: pricePerDfa.toFixed(2),
            })
        }

        if (changedValues && 'redemption_spread' in changedValues) {
            const priceExternal = Number(form.getFieldValue('price_external'))
            const spread = stringToNumber(changedValues.redemption_spread as unknown as string) || 0
            const redeemPricePerDfa = (priceExternal / 100) * spread + priceExternal

            form.setFieldsValue({
                redeem_price_per_dfa: redeemPricePerDfa.toFixed(2),
            })
        }

        if (changedValues && 'supply_invested_type' in changedValues) {
            setIsDiapasonPrice(changedValues.supply_invested_type === SupplyInvestedType.diapason)
        }

        if (changedValues && 'price_source' in changedValues) {
            setRateIndex(changedValues.price_source as ExternalRateIndex)
        }
    }

    const getExternalRate = (index: ExternalRateIndex) => {
        getExternalRateHelper(index)
            .then((result) => {
                form.setFieldsValue({ price_external: (result.item.rate / 100).toFixed(2) })
                calcPricePerDfa(form)
            })
            .catch(() => {
                openMessage({ type: 'error', message: 'Возникли проблемы получении курса ' })
            })
    }

    return (
        <Step
            stepIndex={stepIndex}
            onChangeValues={onChange}
            form={form}
            stepTitle="Условия выпуска ЦФА"
            allowEdit={allowEdit}
            saveAfterEdit
            backFromFirstStep={backFromFirstStep}
        >
            <div className="formRow">
                <WrappedInput
                    label="Наименование выпуска"
                    className="formComponentItem"
                    name="title"
                    placeholder="Предложите уникальное название выпуска"
                    uneditable={isEdit}
                    required
                    maxLength={100}
                />
            </div>
            <div className="formRow">
                <TickerInput
                    uneditable={isEdit}
                    required
                    placeholder="Предложите короткое название из 3-6 символов"
                />
            </div>
            {decisionMakingType !== DecisionMakingType.out_of_platform && (
                <>
                    <CardDivider />
                    <div className="formRow">
                        <WrappedSelect
                            className="formComponentItem"
                            label="Вид прав, удостоверенных ЦФА"
                            name="type_of_law"
                            uneditable={isEdit}
                            required
                            options={optionsTypeOfLaw}
                            disabled
                            initialValue={TypeOfLaw.monetary_claim}
                        />
                    </div>

                    <div className="formRow">
                        <WrappedSelect
                            className="formComponentItem"
                            label="Источник цены"
                            name="price_source_type"
                            uneditable={isEdit}
                            required
                            options={optionsPriceSourceType}
                            initialValue={PriceSource.static}
                        />
                    </div>
                    {isPriceTypeDynamic && (
                        <div className="formRow">
                            <WrappedSelect
                                className="formComponentItem"
                                label="Московская биржа"
                                name="price_source"
                                uneditable={isEdit}
                                required
                                options={optionsPriceSource}
                                initialValue={ExternalRateIndex.GLDRUB_TOM}
                            />
                        </div>
                    )}
                    <CardDivider />

                    {isManual && (
                        <div className="formRow">
                            <WrappedSelect
                                className="formComponentItem"
                                label="Способ осуществления выпуска"
                                name="issue_type"
                                uneditable={isEdit}
                                required
                                disabled
                                options={optionsIssueType}
                                initialValue={IssueTypeDfa.auto}
                            />
                        </div>
                    )}
                    <CountDFA
                        isPriceTypeDynamic={isPriceTypeDynamic}
                        isEdit={isEdit}
                        isDiapasonPrice={isDiapasonPrice}
                    />

                    {!isPriceTypeDynamic && (
                        <PriceSourceStatic isEdit={isEdit} isManual={isManual} />
                    )}

                    {isPriceTypeDynamic && (
                        <PriceSourceDynamic
                            isManual={isManual}
                            isEdit={isEdit}
                            rateIndex={rateIndex}
                            getExternalRate={getExternalRate}
                        />
                    )}

                    <EarlyRepayment
                        uneditable={isEdit}
                        earlyRepayment={Boolean(values?.is_early_repayment)}
                        isDynamicPrice={isPriceTypeDynamic}
                        isShowRepaymentButton={false}
                    />
                </>
            )}
        </Step>
    )
}